.detail-label {
  .detail-label-content {
    display: flex;
    justify-content: space-between;
    align-content: center;
    .label {
      font-size: 12px;
      display: flex;
      align-items: center;
    }
  }
}

.vendor-tab-cont {
  margin-bottom: 12px;
}

.business-info {
  margin-bottom: 24px;
  &.detail-label {

  }
}
