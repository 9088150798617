.permission-container {
    margin-bottom: 16px;
    .permission-name {
        font-weight: 600;
        margin-bottom: 6px;
    }
    .permission-description {
        margin-bottom: 12px;
    }
    .permission-options {
        display: flex;
        .full {
            margin-right: 16px;
        }
    }
}