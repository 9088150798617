.loader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 99;
  background: #0000004d;
  margin: 0;
}