.layout-container {
  min-height: 100vh;
  & .container {
    padding: 0 16px 16px 16px;
    position: relative;
    overflow: scroll;
    height: 100vh;
    .breadcrumb-container {
      background: #f0f2f5;
      padding: 16px 0 ;
      font-weight: normal;
      position: sticky;
      top: 0;
      z-index: 99;
      left: 0;
      right: 0;
      & .breadcrumb-content {
        background: white;
        box-shadow: 2px 3px 7px #e0d9d9;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        align-items: center;
        & .breadcrumb-actions {
          display: flex;
          gap: 12px;
        }
      }
    }
  }
  .logo {
    font-size: 28px;
    margin-top: 24px;
    margin-bottom: 54px;
    text-align: center;
    font-weight: 200;
    .amz {
      color: #698BE4;
    }
    .ware {
      color: #F0F2F5;
    }
  }
}
