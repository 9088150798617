.form-component {
  .ant-col {
    &.center-aligned {
      display: flex;
      align-items: center;
    }
    .ant-form-item-label {
      padding-bottom: 0;
      display: flex;
      //  label {
      //    font-size: 12px;
      //    line-height: 15px;
      //    height: auto;
      //  }
    }
  }
}
