.vendor-detail-info-tab-pane-component {
  display: flex;
  gap: 24px;
  .vendor-detail-section {
    flex: 1;
  }
  .vendor-reseller-detail-section {
    width: 25%;
  }
}

.type-info {
  margin-bottom: 20px;
}
.contact-info {
  & .contact-info-form {
    margin-bottom: 24px;
  }
}
