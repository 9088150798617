.signup-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .signup-container {
    padding: 24px;
    background: white;
    box-shadow: 2px 2px 10px #cccc;
    .title-container {
      h3 {
        text-align: center;
        color: #1183eb;
        font-weight: 600;
      }
    }
    .description {
      font-weight: 600;
    }
    .login-form-button {
      width: 100%;
    }
  }
}