.product-summary-cont {
  padding: 12px;
  background: white;

  .summary-table {
    &-header {
      font-weight: bold;

    }
  }
}
