.product-search-item-cont {
  padding: 12px;
  background: white;
  overflow: scroll;
  .product-search-item-content {
    gap: 20px;
    .product-image-cont {
      width: 20%;
    }
    .product-detail-cont {
      .product-detail-row {
        .product-detail-col {
          gap: 2px;
        }
      }
    }
  }
}
