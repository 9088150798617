.action-buttons-container {
  display: flex;
  &.right {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.left {
    justify-content: flex-end;
  }
}