body {
  background: #f1f2f5;
}
#root {
  height: 100%;
}
.ant-layout {
  height: 100%;
}


// Fonts

.font {
  &-12 {
    font-size: 12px;
  }

  &-weight {
    &-bold {
      font-weight: 500;
    }
  }
}


// Colors

.text-color {
  &-grey {
    color: #6B6B6B;
  }
}

.background {
  &-grey {
    background: #E5E5E5;
  }
}


// Layouts

.padding {
  &-8 {
    padding: 8px;
  }
}

.margin {
  &-0 {
    margin: 0;
  }
  &-2 {
    margin: 2px;
  }
  &-8 {
    &-bottom {
      margin-bottom: 8px;
    }
  }
}

.w {
  &-100 {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  &-40 {
    width: 40%;
    max-width: 40%;
    min-width: 40%;
  }
  &-20 {
    width: 20%;
    max-width: 20%;
    min-width: 20%;
  }
  &-15 {
    width: 15%;
    max-width: 15%;
    min-width: 15%;
  }
}

.gap {
  &-8 {
    gap: 8px;
  }
}

.d-flx {
 display: flex;
}

.flx-d-clm {
  flex-direction: column;
}

.jc {
  &-right {
    justify-content: flex-start;
  }
  &-center {
    justify-content: center;
  }
  &-left {
    justify-content: flex-end;
  }
  &-between {
    justify-content: space-between;
  }
}

.ai {
  &-cen {
    align-items: center;
  }
}

.gap{
  &-12 {
    gap: 12px;
  }
}
.flx {
  &-1 {
    flex: 1;
  }
}
.tag {
  padding: 4px 8px;
  margin: 2px;
  text-align: center;
  &.warning {
    border: 2px solid orange;
    border-radius: 4px;
    background: #fad8ba;
  }
}

.inner-section {
  & .header-container {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  & .main-section {
    padding: 16px;
    background: aliceblue;
    margin: 16px 0;
    border: 1px solid #d8d8d8;
    & .detail-btn {
      height: 40px;
    }
  }
}

.ant-card.ant-card-bordered {
  box-shadow: 2px 3px 7px #e0d9d9;
}

.divider {
  height: 1px;
  width: 100%;
  background: #000000;
  margin: 0.5rem 0;
  &.light-divider {
    background: #ccc;
  }
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f7f7f7;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td  {
  padding: 8px;
}
